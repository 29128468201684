import { lazy } from "react";
import Loadable from "../components/Loadable";

const Learning = Loadable(lazy(() => import("../pages/Learning")));
const LearningPython = Loadable(lazy(() => import("../pages/Learning/LearningPython")));
const LearningIoTControlSetup = Loadable(lazy(() => import("../pages/Learning/LearningIoTControlSetup")));
const LearningDataRetrieval = Loadable(lazy(() => import("../pages/Learning/LearningDataRetrieval")));
const LearningGrammarCheck = Loadable(lazy(() => import("../pages/Learning/LearningGrammarCheck")));
const LearningBookWriter = Loadable(lazy(() => import("../pages/Learning/LearningBookWriter")));
const SingleCourseInfo = Loadable(lazy(() => import("../pages/Courses/SingleCourseInfo")));
const SignUpCourse = Loadable(lazy(() => import("../pages/Courses/SignUpCourse")));
const SingleCourse = Loadable(lazy(() => import("../pages/Courses/SingleCourse")));


const IdeRoutes2 = [
  { path: "/projects", element: <Learning /> }, 
  { path: "/projects/python", element: <LearningPython /> }, 
  { path: "/projects/iot/control-setup", element: <LearningIoTControlSetup /> }, 
  { path: "/projects/ai/data-knowledge-retrieval", element: <LearningDataRetrieval /> },
  { path: "/projects/ai/grammar-check", element: <LearningGrammarCheck /> },
  { path: "/projects/ai/book-writer", element: <LearningBookWriter /> },
  
  { path: "/course/info/:slug", element: <SingleCourseInfo /> },
  { path: "/sign-up/course/:slug", element: <SignUpCourse /> },
  { path: "/course/:slug", element: <SingleCourse /> },

   
];

export default IdeRoutes2;
