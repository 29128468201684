import { lazy } from "react";
import Loadable from "../components/Loadable";

const HomeAdmin = Loadable(lazy(() => import("../pages/Admin")));

const CreateProductCategoriesAdmin = Loadable(lazy(() => import("../pages/Admin/Products/create-category")));
const CreateProductAdmin = Loadable(lazy(() => import("../pages/Admin/Products/create-product")));
const HomeProductsAdmin = Loadable(lazy(() => import("../pages/Admin/Products")));
const EditProductAdmin  = Loadable(lazy(() => import("../pages/Admin/Products/edit-product")));

const CreateBlogCategoriesAdmin = Loadable(lazy(() => import("../pages/Admin/Blog/create-category")));
const CreateBlogAdmin = Loadable(lazy(() => import("../pages/Admin/Blog/create-blog")));
const HomeBlogAdmin = Loadable(lazy(() => import("../pages/Admin/Blog")));
const EditBlogAdmin  = Loadable(lazy(() => import("../pages/Admin/Blog/edit-blog")));

const CreateCourseCategoriesAdmin = Loadable(lazy(() => import("../pages/Admin/Courses/create-category")));
const CreateCourseAdmin = Loadable(lazy(() => import("../pages/Admin/Courses/create-course")));
const HomeCoursesAdmin = Loadable(lazy(() => import("../pages/Admin/Courses")));
const EditCourseAdmin  = Loadable(lazy(() => import("../pages/Admin/Courses/edit-course")));
const CreateCourseLesson  = Loadable(lazy(() => import("../pages/Admin/Courses/create-course-lesson")));
const ViewCourseLesson  = Loadable(lazy(() => import("../pages/Admin/Courses/lessons")));
const EditCourseLesson  = Loadable(lazy(() => import("../pages/Admin/Courses/lessons/edit-lesson")));
const CreateCourseLearningMaterials  = Loadable(lazy(() => import("../pages/Admin/Courses/learning-materials")));

const AllComments  = Loadable(lazy(() => import("../pages/Admin/Comments")));
const AllUsers  = Loadable(lazy(() => import("../pages/Admin/Users")));
const SpecificUserAdmin  = Loadable(lazy(() => import("../pages/Admin/Users/SpecificUser")));
const SpecificUserCourses  = Loadable(lazy(() => import("../pages/Admin/Users/EnrolledCourses")));
const SpecificUserCoursesPaidLessons = Loadable(lazy(() => import("../pages/Admin/Users/EnrolledCourses/paid-lessons")));

const AllUserCourses = Loadable(lazy(() => import("../pages/Admin/Users/EnrolledCourses/all-enrolled-users")));

const PlatformAdmin  = Loadable(lazy(() => import("../pages/Admin/Platform")));
const CreateModalAdmin = Loadable(lazy(() => import("../pages/Admin/CreateModal")));

const ViewVisitedLinks = Loadable(lazy(() => import("../pages/Admin/VisitedLinks")));

const StripeCallback  = Loadable(lazy(() => import("../pages/Admin/Stripe/Callback")));


const AdminRoutes2 = [
  { path: "/cadscreative", element: <HomeAdmin /> }, 

  { path: "/cadscreative/create-product-category", element: <CreateProductCategoriesAdmin /> },
  { path: "/cadscreative/create-product", element: <CreateProductAdmin /> },
  { path: "/cadscreative/all-products", element: <HomeProductsAdmin /> },
  { path: "/cadscreative/edit-product/:slug/:productId", element: <EditProductAdmin /> }, 

  { path: "/cadscreative/create-blog-category", element: <CreateBlogCategoriesAdmin /> },
  { path: "/cadscreative/create-blog", element: <CreateBlogAdmin /> },
  { path: "/cadscreative/blog", element: <HomeBlogAdmin /> },
  { path: "/cadscreative/edit-blog/:blogId", element: <EditBlogAdmin /> },


  { path: "/cadscreative/create-course-category", element: <CreateCourseCategoriesAdmin /> },
  { path: "/cadscreative/create-course", element: <CreateCourseAdmin /> },
  { path: "/cadscreative/all-courses", element: <HomeCoursesAdmin /> },
  { path: "/cadscreative/edit-course/:courseId", element: <EditCourseAdmin /> },
  { path: "/cadscreative/course/lessons/:courseSlug/:courseId", element: <CreateCourseLesson /> },
  { path: "/cadscreative/course/view-lessons/:courseId", element: <ViewCourseLesson /> },
  { path: "/cadscreative/edit-lesson/:courseId/:lessonId", element: <EditCourseLesson /> },

  { path: "/cadscreative/course/learning-materials/:courseSlug/:courseId", element: <CreateCourseLearningMaterials /> },

  { path: "/cadscreative/all-comments", element: <AllComments /> },
  { path: "/cadscreative/all-users", element: <AllUsers /> },
  { path: "/cadscreative/specific-user/:userId", element: <SpecificUserAdmin /> },
  { path: "/cadscreative/enrolled-courses/:userId", element: <SpecificUserCourses /> },
  { path: "/cadscreative/enrolled-courses/paid-lessons/:userId/:courseId", element: <SpecificUserCoursesPaidLessons /> }, 
  { path: "/cadscreative/all-enrolled-courses", element: <AllUserCourses /> },

  { path: "/cadscreative/platform", element: <PlatformAdmin /> },
  { path: "/cadscreative/setup-modal", element: <CreateModalAdmin /> },

  { path: "/cadscreative/view-links", element: <ViewVisitedLinks /> },

  { path: "/cadscreative/stripe/callback", element: <StripeCallback /> }, 




 
];

export default AdminRoutes2;
